import React from "react";
import { TbArrowBack } from "react-icons/tb";

const VideoPlayer = ({ video, setSelectedVideo }) => {
  const handleClose = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="relative flex-1 h-fit max-h-[85vh] mt-4 bg-white rounded-lg animation-scale-in">
      <video
        className="relative rounded-lg h-full max-h-[85vh] aspect-video mx-auto"
        src={video}
        autoPlay
        preload="metadata"
        controls
      />
      <button
        className="absolute flex right-0 top-0 text-3xl p-1 rounded-lg bg-opacity-70 border-2 border-gray-700 shadow-sm bg-white hover:p-2 hover:shadow-md duration-100 ease-out z-10"
        onClick={handleClose}
      >
        <TbArrowBack />
        <span className="text-sm self-center p-1">Atrás</span>
      </button>
    </div>
  );
};

export default VideoPlayer;
