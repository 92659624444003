import { db, storage } from "./firebase";
import {
  collection,
  addDoc,
  updateDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import {
  ref,
  updateMetadata,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getUsername } from "./user";

export const addClip = async (file, info, setProgress, setError) => {
  try {
    const docRef = await addDoc(collection(db, "clips-olimpiadas"), {
      title: info.title,
      note: info.notes,
      user: info.user,
      date: new Date(),
      url: null,
      thumbnail: "",
    });
    uploadClip(file, info, docRef, setProgress, setError);
  } catch (e) {}
};

const uploadClip = async (file, info, docRef, setProgress, setError) => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const formatedDate = `${day}${month}${year}_${hour}${minutes}${seconds}`;

  const rootDir = `clips-olimpiadas/${info.user}/${info.title}_${formatedDate}/`;

  const storageRef = ref(storage, rootDir + file.name);

  const newMetadata = {
    customMetadata: {
      docRef: docRef.id,
    },
  };

  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(progress);
    },
    (error) => {
      setError(true);
    },
    () => {
      updateMetadata(storageRef, newMetadata);
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        updateDoc(doc(db, "clips-olimpiadas", docRef.id), {
          url: downloadURL,
        });
      });
    }
  );
};

export const getClips = async (setLinks, userEmail) => {
  const clips = [];
  const querySnapshot = await getDocs(collection(db, "clips-olimpiadas"));
  querySnapshot.forEach((doc) => {
    if (doc.data().user === getUsername(userEmail)) {
      clips.push(doc.data());
    }
  });
  setLinks(clips);
};
