import React from "react";
import def_thumbnail from "../assets/def_thumbnail.webp";

const ClipItem = ({ clip, handleClick }) => {
  return (
    <li
      className="flex flex-col flex-shrink-0 relative bg-white rounded-lg cursor-pointer shadow-md border border-neutral-200 p-2"
      onClick={() => handleClick(clip)}
    >
      <div className="max-h-20 lg:max-h-28 min-w-max rounded-lg mb-2 self-center bg-neutral-50 border border-neutral-200">
        <img
          src={clip.thumbnail}
          onError={(e) => (e.target.src = def_thumbnail)}
          alt="Clip thumbnail"
          className="max-h-20 lg:max-h-28 min-w-max"
        ></img>
      </div>
      <div className="flex-1 relative max-h-9 overflow-hidden truncate">
        <h4 className="text-sm">{clip.title}</h4>
      </div>
    </li>
  );
};

export default ClipItem;
