import React from "react";
import AuthGoogle from "./AuthGoogle";
import aros from "../assets/logo-aros.webp";

const Header = ({ currentUser }) => {
  return (
    <header className="fixed bg-opacity-40 backdrop-blur flex flex-row bg-white w-full border-b border-neutral-200 z-20 h-16">
      <h1 className="flex items-center ml-5 sm:ml-8">
        <a
          className="text-lg sm:text-xl font-[Poppins] font-extrabold text-olimpic_blue cursor-pointer"
          href="https://nomolandia.es"
        >
          NOM
        </a>
        <span className="text-lg sm:text-xl font-[Poppins] font-extrabold">
          OLIMPIADAS
        </span>
      </h1>
      <div className="flex items-center group cursor-default">
        <div className="p-1 object-contain">
          <img
            src={aros}
            alt="Logo NomoOlimpiadas"
            className="w-16 sm:w-32 object-cover overflow-visible aspect-square"
          />
        </div>
        <span class="absolute sm:static rotate-12 translate-x-14 sm:-translate-x-4 -translate-y-2.5 bg-gray-100 text-[0.5rem] sm:text-xs font-medium mb-2 px-0.5 sm:px-1 py-0.5 rounded border border-neutral-200">
          2023
        </span>
      </div>
      <AuthGoogle currentUser={currentUser} />
    </header>
  );
};

export default Header;
