import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBrMHVgQMb04_GNr162trrTQUuqhGGdBV4",
  authDomain: "nomolandia-6fc1a.firebaseapp.com",
  projectId: "nomolandia-6fc1a",
  storageBucket: "nomolandia-6fc1a.appspot.com",
  messagingSenderId: "915221718149",
  appId: "1:915221718149:web:c8c6046b4214bb69d2f5ec",
  measurementId: "G-F9TYT7GCSG",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

export const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult) {
      auth.updateCurrentUser(authResult.user);
      return false;
    },
    signInFailure: function (error) {
      return true;
    },
  },
  signInFlow: "popup",
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
    },
  ],
};

const analytics = getAnalytics(app);
