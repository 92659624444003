import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import FileDropArea from "./components/FileDropArea";
import FileOptions from "./components/FileOptions";
import ClipList from "./components/ClipList";
import VideoPlayer from "./components/VideoPlayer";
import { auth } from "./controllers/firebase";
import landing_video from "./assets/landing_video.webm";

function App() {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserNomo, setIsUserNomo] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setCurrentUser);
    return unsubscribe;
  }, [setCurrentUser]);

  useEffect(() => {
    const checkNomoClaim = async () => {
      const idTokenResult = await currentUser?.getIdTokenResult();
      setIsUserNomo(!!idTokenResult?.claims.nomo);
    };
    checkNomoClaim();
  }, [currentUser]);

  const loginScreen = () => {
    return (
      <div className="flex flex-col sm:flex-row pt-16 items-center h-full w-full">
        <div className="bg-white bg-opacity-10 sm:backdrop-blur-sm border border-neutral-200 rounded-xl shadow-lg text-5xl lg:w-1/2 lg:ml-32 m-10 lg:mt-10 mt-20 font-bold text-black sm:leading-tight z-20">
          {seAceptanClips ? (
            <h1 className="m-10 sm:m-16">
              <span className="underline decoration-wavy decoration-olimpic_green">
                Inicia sesión
              </span>{" "}
              para enviar tus <span className="text-olimpic_yellow">clips</span>
            </h1>
          ) : (
            <h1 className="m-10 sm:m-16">
              Ya no se aceptan{" "}
              <span className="text-olimpic_yellow">clips</span>
            </h1>
          )}
          <video
            src={landing_video}
            autoPlay
            disableRemotePlayback
            loop
            muted
            className="sm:hidden rounded-xl p-0 m-0 w-full z-10"
          ></video>
        </div>
        <video
          src={landing_video}
          autoPlay
          disableRemotePlayback
          loop
          muted
          className="fixed hidden sm:inline w-screen sm:mr-28 shadow-inner shadow-white z-10"
        ></video>
      </div>
    );
  };

  const seAceptanClips = false;

  return (
    <div className="text-black h-full w-full">
      <Header currentUser={currentUser} />
      {!seAceptanClips || currentUser === null || !isUserNomo ? (
        loginScreen()
      ) : (
        <>
          <div
            className={`pt-16 bg-white sm:h-full flex w-full ${
              selectedVideo ? "" : "sm:pb-56"
            }`}
          >
            <main className="relative h-full w-full sm:pr-96 py-4 flex flex-col sm:flex-row items-stretch">
              {selectedVideo ? (
                <VideoPlayer
                  video={selectedVideo.url}
                  setSelectedVideo={setSelectedVideo}
                />
              ) : (
                <>
                  <FileDropArea
                    setFile={setFile}
                    file={file}
                    progress={progress}
                    error={error}
                  />
                  <FileOptions
                    setFile={setFile}
                    file={file}
                    userEmail={currentUser?.email}
                    setProgress={setProgress}
                    setError={setError}
                  />
                </>
              )}
            </main>
          </div>
          <ClipList
            userEmail={currentUser?.email}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
          />
        </>
      )}
    </div>
  );
}

export default App;
