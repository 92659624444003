import React, { useState, useCallback } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { addClip } from "../controllers/clip";
import { getUsername } from "../controllers/user";

const FileOptions = ({ setFile, file, userEmail, setProgress, setError }) => {
  const initialState = { title: "", notes: "", user: "" };
  const [info, setInfo] = useState(initialState);

  const handleUpload = useCallback(() => {
    addClip(file, info, setProgress, setError);
  }, [file, info, setProgress, setError]);

  const handleInputChange = (e) => {
    e.target.classList.remove("border-red-500");
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value, user: getUsername(userEmail) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (info.title) {
      handleUpload();
      setInfo(initialState);
      setFile(null);
    } else {
      // Set focus on the first input and in the select if it's empty
      if (!info.title) {
        document.getElementById("title").classList.add("border-red-500");
      }
    }
  };

  return (
    <form className="sm:fixed right-0 sm:h-full sm:max-h-[65vh] w-11/12 sm:w-96 self-center sm:self-auto sm:mr-4 p-1 sm:overflow-auto bg-white border border-neutral-200 rounded-lg shadow-lg flex flex-col gap-6 scrollbar z-10">
      <label className="mt-4 mx-6">
        Título
        <input
          className="block mt-1 w-full py-2 px-3 ring-neutral-300 border-neutral-200 border border-input bg-transparent ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 shadow-sm rounded-md outline-none focus:shadow-md transition-shadow"
          type="text"
          name="title"
          value={info.title}
          onChange={handleInputChange}
          onInvalid={(e) => {
            e.target.setCustomValidity("Por favor, ingresa un título");
          }}
          onInput={(e) => {
            e.target.setCustomValidity("");
          }}
          id="title"
          required
        />
      </label>
      <div className="mx-6 sm:mt-auto mb-6">
        <label className="text-black">
          Notas{" "}
          <span className="text-neutral-900 text-xs font-normal">
            (Opcional)
          </span>
          <textarea
            className="form-input block mt-1 w-full py-2 px-3 border ring-neutral-300 border-input bg-transparent ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 border-neutral-200 shadow-sm rounded-md outline-none focus:shadow-md transition-shadow"
            name="notes"
            value={info.notes}
            onChange={handleInputChange}
          />
        </label>
        <button
          className="flex gap-2 items-center justify-center py-2 px-3 bg-green-500 hover:bg-opacity-90 text-white font-semibold rounded-md duration-150 mt-5"
          type="submit"
          onClick={handleSubmit}
        >
          <FaPaperPlane />
          Enviar
        </button>
      </div>
    </form>
  );
};

export default FileOptions;
